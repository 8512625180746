export const GET_ALL_COMPONENT = "GET_ALL_COMPONENT";
export const CREATE_COMPONENT = "CREATE_COMPONENT";
export const UPDATE_COMPONENT = "UPDATE_COMPONENT";
export const DELETE_COMPONENT = "COMPONENT_DELETE";
export const ADD_TO_COMPONENT = "ADD_TO_COMPONENT";
export const ACTIVE_COMPONENT = "ACTIVE_COMPONENT";
export const GET_ALL_PHASE_COMPONENT = "GET_ALL_PHASE_COMPONENT";
export const DELETE_PHASE_COMPONENT = "DELETE_PHASE_COMPONENT";
export const CREATE_PHASE_COMPONENT = "CREATE_PHASE_COMPONENT";
export const API = "API";
export const SET_PROJECT = "SET_PROJECT";