export const GET_ALL_LOCATION = "GET_ALL_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "LOCATION_DELETE";
export const ADD_TO_LOCATION = "ADD_TO_LOCATION";
export const ACTIVE_LOCATION = "ACTIVE_LOCATION";
export const GET_LOCATION_BY_TAG = "GET_LOCATION_BY_TAG";
export const GET_CHILD_LOCATIONS = "GET_CHILD_LOCATIONS"
export const GET_ALL_LOCATION_TAG = "GET_ALL_LOCATION_TAG";
export const API = "API";
export const SET_PROJECT = "SET_PROJECT";
export const SET_ERROR = "SET_ERROR";
export const UNSET_ERROR = "UNSET_ERROR"