export const GET_ALL_QUESTION= "GET_ALL_PHASE";
export const CREATE_QUESTION= "CREATE_PHASE";
export const UPDATE_QUESTION= "UPDATE_PHASE";
export const DELETE_QUESTION= "PHASE_DELETE";
export const ADD_TO_QUESTION= "ADD_TO_PHASE";
export const ACTIVE_QUESTION= "ACTIVE_PHASE";
export const SEARCH_CONCEPT= "SEARCH_CONCEPT";
export const CREATE_ANSWER= "CREATE_ANSWER";
export const CREATE_CONCEPT= "CREATE_CONCEPT";
export const SEARCH_FIELD= "SEARCH_FIELD";
export const GET_FORM_BY_UUID= "GET_FORM_BY_UUID";
export const SET_PROJECT_QUESTION = "SET_PROJECT_QUESTION";