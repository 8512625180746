export const GET_ALL_FORM = "GET_ALL_FORM";
export const CREATE_FORM = "CREATE_FORM";
export const UPDATE_FORM = "UPDATE_FORM";
export const DELETE_FORM = "FORM_DELETE";
export const ADD_TO_FORM = "ADD_TO_FORM";
export const ACTIVE_FORM = "ACTIVE_FORM";
export const GET_ALL_FORM_TAG = "GET_ALL_FORM_TAG";
export const SEARCH_FORM = "SEARCH_FORM";
export const GET_FORM_BY_UUID = "GET_FORM_BY_UUID";

export const CREATE_COMPONENT_FORM_RELATION = "CREATE_COMPONENT_FORM_RELATION";
export const GET_COMPONENT_FORM_RELATION = "GET_COMPONENT_FORM_RELATION";
export const DELETE_COMPONENT_FORM_RELATION = "DELETE_COMPONENT_FORM_RELATION";
export const API = "API";
export const SET_PROJECT = "SET_PROJECT";
export const FORM_SUBMISSION = "FORM_SUBMISSION";
export const GET_COMPONENT_FORM_BY_COMPONENT = "GET_COMPONENT_FORM_BY_COMPONENT";
export const GET_FORM_FIELDS_BY_FORM = "GET_FORM_FIELDS_BY_FORM";
export const GET_FORM_FIELD_BY_UUID = "GET_FORM_FIELD_BY_UUID";
export const GET_COMPONENT_FORM_BY_UUID = "GET_COMPONENT_FORM_BY_UUID"