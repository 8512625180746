export const GET_ALL_WORKFORCE = "GET_ALL_WORKFORCE";
export const CREATE_WORKFORCE = "CREATE_WORKFORCE";
export const UPDATE_WORKFORCE = "UPDATE_WORKFORCE";
export const DELETE_WORKFORCE = "WORKFORCE_DELETE";
export const ADD_TO_WORKFORCE = "ADD_TO_WORKFORCE";
export const ACTIVE_WORKFORCE = "ACTIVE_WORKFORCE";
export const GET_PARTICIPANT_BY_USER = "GET_PARTICIPANT_BY_USER"
export const GET_WORKFORCE_SALARY_TYPE = "GET_WORKFORCE_SALARY_TYPE";
export const API = "API";
export const SET_PROJECT = "SET_PROJECT";
