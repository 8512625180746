export const GET_ALL_USER = "GET_ALL_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "USER_DELETE";
export const ADD_TO_USER = "ADD_TO_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const SEARCH_USER = "SEARCH_USER";
export const API = "API";
export const SET_PROJECT = "SET_PROJECT";
export const SET_ERROR = "SET_ERROR";

