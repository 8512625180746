export const GET_ALL_PATIENT = "GET_ALL_PATIENT";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DELETE_PATIENT = "PATIENT_DELETE";
export const ADD_TO_PATIENT = "ADD_TO_PATIENT";
export const ACTIVE_PATIENT = "ACTIVE_PATIENT";
export const SEARCH_PATIENT = "SEARCH_PATIENT";

export const GET_ALL_PATIENT_TYPE = "GET_ALL_PATIENT_TYPE";
export const CREATE_PATIENT_TYPE = "CREATE_PATIENT_TYPE";
export const UPDATE_PATIENT_TYPE = "UPDATE_PATIENT_TYPE";
export const DELETE_PATIENT_TYPE = "PATIENT_DELETE_TYPE";
export const ADD_TO_PATIENT_TYPE = "ADD_TO_PATIENT_TYPE";
export const ACTIVE_PATIENT_TYPE = "ACTIVE_PATIENT_TYPE";

export const API = "API";
export const SET_SEARCH_PROJECT = "SET_SEARCH_PROJECT";