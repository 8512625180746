export const GET_ALL_ASSET = "GET_ALL_ASSET";
export const CREATE_ASSET = "CREATE_ASSET";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const DELETE_ASSET = "ASSET_DELETE";
export const ADD_TO_ASSET = "ADD_TO_ASSET";
export const ACTIVE_ASSET = "ACTIVE_ASSET";
export const GET_ALL_ASSET_TYPE = "GET_ALL_ASSET_TYPE";
export const CREATE_ASSET_TYPE = "CREATE_ASSET_TYPE";
export const UPDATE_ASSET_TYPE = "UPDATE_ASSET_TYPE";
export const DELETE_ASSET_TYPE = "ASSET_DELETE_TYPE";
export const ADD_TO_ASSET_TYPE = "ADD_TO_ASSET_TYPE";
export const ACTIVE_ASSET_TYPE = "ACTIVE_ASSET_TYPE";


export const GET_ALL_ASSET_CATEGORY = "GET_ALL_ASSET_CATEGORY";
export const CREATE_ASSET_CATEGORY = "CREATE_ASSET_CATEGORY";
export const UPDATE_ASSET_CATEGORY = "UPDATE_ASSET_CATEGORY";
export const DELETE_ASSET_CATEGORY = "ASSET_DELETE_CATEGORY";
export const ADD_TO_ASSET_CATEGORY = "ADD_TO_ASSET_CATEGORY";
export const ACTIVE_ASSET_CATEGORY = "ACTIVE_ASSET_CATEGORY";




export const API = "API";
export const SET_PROJECT = "SET_PROJECT";